export enum ROUTES {
  // Logs = '/platform/:platform/environment/:environment/logs',
  Blueprints = '/blueprints',
  Docs = '/docs',
  AnsibleRuns = '/ansibleruns',
  HealthStatus = '/healthstatus',
  BlueprintVersions = '/blueprints/:id/versions',
  PlatformDetails = '/platform/:platform/environment/:environment',
  LandingPage = '/',
  logs = '/logs',
}
