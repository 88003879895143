import * as React from 'react';
import { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.module.scss';
import '@maersk-global/fonts/maeu/fonts.css';
import '@maersk-global/mds-design-tokens/maersk/light/css/design-tokens-px.css';
import '@maersk-global/mds-foundations/css/foundations.css';
import { ROUTES } from './constants/routes.constant';
import { getLogsUrl, getPlatformEnvUrl } from '../src/utils/Urls';
// Lazy-loaded components
const Header = React.lazy(() => import('./components/dashboard/header'));
const LandingPage = React.lazy(() => import('./pages/LandingPage/LandingPage'));
const Logs = React.lazy(() => import('./components/logs/logs'));
const Blueprints = React.lazy(
  () => import('./components/Blueprint/Blueprints')
);
const BlueprintVersions = React.lazy(
  () => import('./components/Blueprint/BlueprintVersions')
);
const HealthStatus = React.lazy(
  () => import('./components/healthstatus/healthstatus')
);
const AnsibleRuns = React.lazy(
  () => import('./components/AnsibleRuns/ansibleruns')
);
const PlatformDetails = React.lazy(
  () => import('./components/PlatformDetails/PlatfromDetails')
);

const App = () => {
  const pathname = location.pathname;
  const platformCode = pathname.split('/')[2];
  const environmentCode = pathname.split('/')[4];
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
        <Routes>
          <Route path={ROUTES.LandingPage} element={<LandingPage />} />
          <Route path={ROUTES.Blueprints} element={<Blueprints />} />
          <Route
            path={ROUTES.BlueprintVersions}
            element={<BlueprintVersions />}
          />
          <Route path={ROUTES.HealthStatus} element={<HealthStatus />} />
          {/* <Route path={ROUTES.AnsibleRuns} element={<AnsibleRuns />} /> */}
          <Route path={ROUTES.PlatformDetails} element={<PlatformDetails />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
